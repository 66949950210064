import React, { useEffect, useRef, useState } from "react";
import durgamata from "../../images/discover-more/durgamatha.svg";
import sevaname from "../../images/champaign/image-01.png";
import sevaname1 from "../../images/champaign/image-02.png";
import sevaname2 from "../../images/champaign/image-03.png";
import bookingcart from "../../images/individual/add-to-cart.svg";
import BasicSelect from "./BasicSelect";
import { Link, useNavigate } from "react-router-dom";
import BasicAccordion from "./Accordion";
import {
  Grid,
  Button,
  Collapse,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
} from "@mui/material";
import EventFaq from "./EventFaq";
import instance from "../../utils/Api";
import Typography from "@mui/material/Typography";
import { useAuth } from "../../utils/Auth";
import { useCart } from "../../pages/CartContext";
import Swipper3 from "./Swipper3";
import LoginDailog from "../LoginDailog";
import axios from "axios";
import MultipleSelectPlaceholder2 from "../MultipleSelectPlaceholder2";
import api from "../../config/backend";
import ReactGA from "react-ga4";
import CloseIcon from "@mui/icons-material/Close";
import Process from "./Process";
import CustomizedDialogs from "../CustomizedDialogs";

const CustomTabPanel4 = ({
  sevas,
  setTriggerRefresh,
  isIndia,
  productDetails,
  setDonationValue,
}) => {
  const token = localStorage.getItem("token");
  const section1Ref = useRef(null);
  const section2Ref = useRef(null);
  const section3Ref = useRef(null);
  const section4Ref = useRef(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const user = useAuth();
  useEffect(() => {
    setIsLoggedIn(user.isLogin);
  }, []);

  const { cartTotal, updateCartTotal } = useCart();
  const [visibleSection, setVisibleSection] = useState("section1");
  const updatesevasdata = sevas.event_updates;
  const sevasdata = sevas;
  const eventFaq = sevas.event_faqs;

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [openModel, setOpenModel] = useState(null);
  const [selectedPriceList, setSelectedPriceList] = useState([]);
  const [sevaCounts, setSevaCounts] = useState({});

  const navigate = useNavigate();
  const [readMore, setReadMore] = useState({});

  const modifyHtmlWithFullImagePath = (htmlContent) => {
    const baseUrl = "https://api-backend.devasmriti.com/"; // Base URL for your images
  
    // Replace relative image src with the full URL
    const updatedHtml = htmlContent?.replace(
      /<img src="\.\.(.*?)"/g, 
      `<img src="${baseUrl}$1"`
    );
  
    return updatedHtml;
  };


  

  const handleReadOpen = (sevasId) => {
    if (sevasId) {
      setReadMore((prevState) => ({
        ...prevState,
        [sevasId]: true,
      }));
    }
  };

  const handleReadClose = (sevasId) => {
    setReadMore((prevState) => ({
      ...prevState,
      [sevasId]: false,
    }));
  };

  const openDialog = () => {
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  useEffect(() => {
    sevasdata &&
      setSelectedPriceList(
        sevasdata.map((seva) => {
          const def = seva.seva_prices.find((option) => option.is_default);
          return {
            seva_id: seva.id,
            selected_price: def ? def : seva.seva_prices[0],
          };
        })
      );
  }, [sevasdata]);

  useEffect(() => {
    const handleScroll = () => {
      const sectionRefs = [
        { ref: section1Ref, name: "section1" },
        { ref: section2Ref, name: "section2" },
        { ref: section3Ref, name: "section3" },
        { ref: section4Ref, name: "section4" },
      ];
      const currentScrollPosition = window.scrollY;
      for (let i = 0; i < sectionRefs.length; i++) {
        const section = sectionRefs[i];
        if (section.ref.current) {
          const sectionTop = section.ref.current.offsetTop - 210;
          const sectionBottom = sectionTop + section.ref.current.clientHeight;

          if (
            currentScrollPosition >= sectionTop &&
            currentScrollPosition <= sectionBottom
          ) {
            setVisibleSection(section.name);
          }
        }
      }
    };

    const handleResize = () => { };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleNavClick = (ref) => {
    window.scrollTo({
      top: ref.current.offsetTop - 190,
      behavior: "smooth",
    });
    setVisibleSection(ref.current.dataset.section);
  };

  const handleAddToCart = async (seva) => {
    if (token) {
      const cartData = {
        cart: {
          user_id: localStorage.getItem("user_id"),
          seva_id: seva.id,
          seva_price_id: seva.seva_prices[0].id,
          is_prasadam_available: 0,
          qty: 1,
        },
      };

      try {
        const response = await axios.post(
          `${api}/api/cart`,
          { cart: cartData },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        if (response.data.success === 1) {
          setTriggerRefresh((prev) => prev + 1);
        } else {
          console.log("errorrrr");
        }
      } catch (error) {
        console.error("Error adding items to the cart:", error);
      }
    } else {
      openDialog();
    }
  };

  const [sevaForDialog, setSevaForDialog] = useState(null);

  const handleOpenModal = (seva) => {
    setOpenModel(seva.id);
  };
  const handleClose = () => {
    setOpenModel(false);
  };

  const isMobile = window.matchMedia("(max-width: 768px)").matches;

  // const handleButtonClick = (seva) => {
  //   window.fbq("track", "AddToCart");
  //   window.fbq("track", "InitiateCheckout");
  //   const sevaPrices = seva.seva_prices;

  //   if (isMobile) {
  //     if (!sevaPrices || sevaPrices.length <= 1) {
  //       handleBookSeva(seva);
  //     } else {
  //       handleOpenModal(seva);
  //     }
  //   } else {
  //     handleBookSeva(seva);
  //   }
  // };

  function handlePriceChange(selected_price, id) {
    setSelectedPriceList((previousValue) => {
      if (previousValue) {
        const copy = JSON.parse(JSON.stringify(previousValue));
        const index = copy.findIndex((item) => item.seva_id === id);
        copy[index] = { seva_id: id, selected_price };
        return copy;
      }
      return previousValue;
    });
  }

  const handleAddSeva = (seva) => {
    setSevaCounts((prevCounts) => {
      const newCount = (prevCounts[seva.id] || 0) + 1;
      setDonationValue((prevValue) =>
        (Number(prevValue) || 0) + Number(seva?.seva_prices[0]?.selling_price)
      );
      return { ...prevCounts, [seva.id]: newCount };
    });
  };

  const handleIncrementSeva = (seva) => {
    setSevaCounts((prevCounts) => {
      const newCount = (prevCounts[seva.id] || 0) + 1;
      setDonationValue((prevValue) =>
        (Number(prevValue) || 0) + Number(seva?.seva_prices[0]?.selling_price)
      );
      return { ...prevCounts, [seva.id]: newCount };
    });
  };

  const handleDecrementSeva = (seva) => {
    setSevaCounts((prevCounts) => {
      const currentCount = prevCounts[seva.id] || 0;
      if (currentCount > 0) {
        setDonationValue((prevValue) =>
          (Number(prevValue) || 0) - Number(seva?.seva_prices[0]?.selling_price)
        );
        return { ...prevCounts, [seva.id]: currentCount - 1 };
      }
      return prevCounts;
    });
  };

  return (
    <>
      <nav className="nav">
        <Button
          className={visibleSection === "section1" ? "active" : ""}
          onClick={() => handleNavClick(section1Ref)}
        >
          Seva
        </Button>
        <Button
          className={visibleSection === "section2" ? "active" : ""}
          onClick={() => handleNavClick(section2Ref)}
        >
          Description
        </Button>
        <Button
          className={visibleSection === "section3" ? "active" : ""}
          onClick={() => handleNavClick(section3Ref)}
        >
          Updates
        </Button>
      </nav>

      <div ref={section1Ref} data-section="section1" className="section1">
        <div className="">
          <h3>Sevas</h3>
          <Grid container alignItems={"flex-start"} spacing={2}>
            {sevasdata &&
              sevasdata.map((seva, index) => {
                const sevaCount = sevaCounts[seva.id] || 0;

                return (
                  <Grid
                    justify="flex-start"
                    key={index}
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={4}
                  >
                    <div className="seva-box">
                      <img
                        src={`${seva.feature_image_id.domain}${seva.feature_image_id.url}`}
                        alt={seva.feature_image_id.name}
                      />
                      <h4 className="sevaTitle">{seva.title}</h4>
                      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div className="sevaPrice">
                          ₹{seva?.seva_prices[0]?.selling_price}
                        </div>
                        <div className="btnWrapper" style={{ marginTop: '-10px' }}>
                          {sevaCount === 0 ? (
                            <Button
                              onClick={() => handleAddSeva(seva)}
                              variant="contained"
                              disableRipple
                              disableElevation
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                borderRadius: "10px",
                                padding: "10px 20px",
                                color: "white",
                                fontFamily: "Objectivity",
                                fontSize: "15px",
                                fontWeight: 500,
                                textTransform: "unset",
                                backgroundColor: "#ff5b00",
                                whiteSpace: "nowrap",
                                // flex: "1 1 auto",
                                fontSize: {
                                  xs: "13px",
                                  sm: "14px",
                                },
                                "&:hover": {
                                  backgroundColor: "#ff7b33",
                                },
                              }}
                            >
                              <span>Add</span>
                            </Button>
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                borderRadius: "10px",
                                padding: "10px 10px",
                                color: "white",
                                fontFamily: "Objectivity",
                                fontSize: "15px",
                                fontWeight: 500,
                                textTransform: "unset",
                                backgroundColor: "#ff5b00",
                                whiteSpace: "nowrap",
                                // flex: "1 1 auto",
                                fontSize: {
                                  xs: "13px",
                                  sm: "14px",
                                },
                              }}
                            >
                              <Button
                                onClick={() => handleDecrementSeva(seva)}
                                sx={{
                                  minWidth: "30px",
                                  height: "30px",
                                  borderRadius: "50%",
                                  padding: 0,
                                  backgroundColor: "#fff",
                                  color: "#ff5b00",
                                  "&:hover": {
                                    backgroundColor: "#ffe6cc",
                                  },
                                  fontSize: '16px',
                                  fontWeight: 700
                                }}
                              >
                                -
                              </Button>
                              <span style={{ margin: "0 10px" }}>{sevaCount}</span>
                              <Button
                                onClick={() => handleIncrementSeva(seva)}
                                sx={{
                                  minWidth: "30px",
                                  height: "30px",
                                  borderRadius: "50%",
                                  padding: 0,
                                  backgroundColor: "#fff",
                                  color: "#ff5b00",
                                  "&:hover": {
                                    backgroundColor: "#ffe6cc",
                                  },
                                  fontSize: '16px',
                                  fontWeight: 700
                                }}
                              >
                                +
                              </Button>
                            </div>
                          )}
                        </div>
                      </div>


                      <div
                        className="sevaDesc"
                        onClick={() => handleReadOpen(seva?.id)}
                      >
                        <div>Description</div>
                        <img
                          onClick={() => handleReadOpen(seva?.id)}
                          src={require("../../images/arrowDown.svg").default}
                          alt=""
                        />
                      </div>
                      <Dialog
                        open={readMore[seva?.id] || false}
                        onClose={() => handleReadClose(seva?.id)}
                        aria-labelledby="seva-description-title"
                        sx={{
                          "& .MuiDialog-paperScrollPaper": {
                            borderRadius: "8px!important",
                            boxShadow: "0px 4px 15px 0px #0000000D",
                            padding: "0 6px",
                          },
                          "& .MuiDialogContent-root": {
                            "&::-webkit-scrollbar": {
                              width: "6px",
                            },
                            "&::-webkit-scrollbar-track": {
                              backgroundColor: "#EAEAEA",
                            },
                            "&::-webkit-scrollbar-thumb": {
                              backgroundColor: "#ff5b00",
                              borderRadius: "8px",
                              transform: "translateX(2px)",
                              height: "4px!important",
                            },
                          },
                        }}
                      >
                        <DialogTitle id="seva-description-title">
                          Description
                          <IconButton
                            aria-label="close"
                            onClick={() => handleReadClose(seva?.id)}
                            sx={{
                              position: "absolute",
                              right: 8,
                              top: 8,
                              color: "#ff5b00",
                              fontWeight: 700,
                              cursor: "pointer",
                            }}
                          >
                            <CloseIcon />
                          </IconButton>
                        </DialogTitle>
                        <DialogContent className="custom-camp-dialog-modal">
                          <DialogContentText
                            dangerouslySetInnerHTML={{
                              __html: seva?.description,
                            }}
                          ></DialogContentText>
                        </DialogContent>
                      </Dialog>


                    </div>
                  </Grid>
                );
              })}
          </Grid>
        </div>
      </div>

      <div ref={section2Ref} data-section="section2" className="section2">
        <div className="">
          <div className="description-content">
            <h3>Description</h3>
            <p
              dangerouslySetInnerHTML={{ 
                // __html: productDetails?.description 
                __html: modifyHtmlWithFullImagePath(productDetails?.description),
              }}
            ></p>
          </div>
        </div>
      </div>
      <div ref={section3Ref} data-section="section3" className="section3" >
        <div className="description-content" style={{background:'white', padding:'20px', borderRadius:'20px'}}>
          <h3>Updates</h3>
          {productDetails?.additional_information &&
            productDetails?.additional_information.length === 0 ? (
            <div className="accordion">
              <Typography variant="h4" className="main-content">
                There are no updates for this events
              </Typography>
            </div>
          ) : (
            <p
              dangerouslySetInnerHTML={{
                __html: modifyHtmlWithFullImagePath(productDetails?.additional_information),
                // __html: productDetails?.additional_information,
              }}
            ></p>
          )}
        </div>
      </div>
      <div ref={section4Ref} data-section="section4" className="section4">
        <div className="">
          <div className="faq-2">
            <div className="faq-main-content-2">
              {/* <EventFaq /> */}
              {/* <h3>Puja Process</h3> */}
              {/* <BasicAccordion /> */}
              {/* <Process /> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomTabPanel4;
